import React from 'react';
import ScrollTop from "./ScrollTop";
import CopyrightTwo from "./CopyrightTwo";



const FooterTwo = () => {
    return (
        <>
            <div className="footer-style-2">
                <CopyrightTwo />
            </div>
            <ScrollTop />
        </>
    )
}

export default FooterTwo;
