import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import Separator from "../elements/separator/Separator";
import FooterTwo from '../common/footer/FooterTwo';
import AboutOne from '../elements/about/AboutOne';
import Mission from "../elements/about/Mission";
import Mint from "../elements/mint/Mint";

const Startup = () => {
    return (
        <>
            <SEO title="InitPunks" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small btn-icon round" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-2 variation-3 height-850 bg_image bg_image_fixed" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/InitPunks-Heroimage03.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="inner text-left">
                                    <h2>Welcome to the world of</h2>
                                     <h1 className="title"><span className="theme-gradient">InitPunks NFT</span></h1>
                                    <h3 className="description">InitPunks is an imaginary origin story to pay tribute to the NFT community tweeters and the OG Cryptopunks.</h3>
                                    <div className="button-group mt--40 mt_sm--20">
                                        <a className="btn-default" target="_blank" href="https://discord.gg/5sGErWuAZz">Join Discord</a>
                                        {/* <Link className="btn-default btn-icon" to="#">Mint <i className="icon"><FiArrowRight /></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                <Mint />
                
                <Separator />

                {/* Start About Area  */}
                <AboutOne />                            
                {/* End About Area  */}

                <Separator />

                {/* Start Mission Area   */}
                <Mission />                    
                {/* Start Mission Area  */}

                <Separator/>

                <FooterTwo />
            </main>
        </>
    )
}

export default Startup;
