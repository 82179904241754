import React from 'react';
import Typed from 'react-typed';

const AboutOne = () => {
    return (
        <div id="about" className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/InitPunks-Tree.jpg" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Origin Story <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "InitPunks.",
                                            "InitPunks.",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>Our AI(Artificial Intelligence) was born on Feb 5, 1990(10000 days before the launch of
                                     CryptoPunks). Our AI superpower was that it could read inspirational Tweets 
                                     from the future but its weakness was its inability to draw. But it practiced
                                      everyday by taking inspiration from history and improved a lot. Now the AI's
                                       earlier drawings from its sketchbook are available to you as NFTs. By minting
                                        you will also get access to #NFTMoonifesto which contains all the Tweets you
                                         need to launch your NFTs to the Moon!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
