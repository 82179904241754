import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({customStyle}) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="1">
            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                Why is the mint price not uniform? 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>The images generated by the AI will get progressively better looking since the AI gets better after iterations. So the price quadruples every 2500 mints. The fun here is not just what you see on the image but also what tweets you discover in the #NFTMoonifesto</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                Why do some of the images not look exactly like a punk?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>In GAN(generative adversarial network)s, there is something called a generator and a discriminator. Generator is a deep learning system that keeps creating image of a punk and asks the discriminator, which is another deep learning system, -whether the image is a punk. All the images in InitPunks are the images that couldn’t convince the discriminator. This was an interesting dilemma as an artist. Had we selected images that looked like a CryptoPunk the whole collection would look like it was plagiarized. If we selected the ones from earlier versions, it wouldn’t look anything like a punk!</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    How many unique initpunks are there in total? 
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>There are 10,000 unique InitPunks in total. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                What is Noomlabs?
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>Noomlabs is a group of crypto enthusiasts working for Microsoft, Amazon during the day and following our passion at night. We collaborate with different artists and help them jump start their web 3.0 journey. We have many more exciting NFT projects lined up.</Card.Body>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}
export default AccordionOne

